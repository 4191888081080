<template>
  <transition name="fade">
    <div
      v-if="visible"
      :class="[
        'modal',
        { 'modal--is-closable': !noCloseBtn },
      ]"
    >
      <div
        class="modal__dialog"
        ref="modalRef"
        @click.stop="() => {}"
      >
        <div class="modal__header">
          <div class="modal__title">
            {{ title }}
            <div
              v-if="subtitle"
              class="modal__subtitle"
            >
              {{ subtitle }}
            </div>
            <slot
              v-if="!subtitle"
              name="subtitle"
            />
          </div>

          <Button
            v-if="!noCloseBtn"
            variant="tertiary"
            icon="x"
            icon-only
            small
            @click="close"
          />
        </div>

        <div class="modal__content">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Button from '@/components/common/Button';

export default {
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: 'Modal',
    },
    subtitle: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    noCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    close: {},
  },
  setup(_, { emit }) {
    const close = () => {
      emit('close');
    };

    const modalRef = ref(null);

    onClickOutside(modalRef, () => {
      emit('close');
    });

    return {
      close,
      modalRef,
    };
  },
};
</script>

<style lang="scss">
.modal {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modalZIndex;
  pointer-events: all;

  &--is-closable {
    cursor: pointer;
  }

  .modal__dialog {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    cursor: default;
  }

  .modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 32px 0;
    padding-left: 25px;
    height: 62px;

    .icon {
      stroke: #000;
      cursor: pointer;
    }
  }

  .modal__title {
    font-size: 15.72px;
    font-family: 'Poppins';
    font-weight: 600;
    padding: 0 8px;
  }

  .modal__subtitle {
    font-size: 12px;
    line-height: 14px;
    color: $gray700;
    margin-top: 8px;
  }

  .modal__content {
    flex: 1;
    height: calc(100% - 61px);
  }

  .modal__footer {
    display: flex;
    align-items: center;
  }
}
</style>
